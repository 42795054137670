window.addEventListener('DOMContentLoaded', function () {
    const menu = document.getElementById('mobile-menu');
    const menuLinks = menu.querySelectorAll('#mobile-menu a');
    if (menuLinks && menu) {
        for (let i = 0; i < menuLinks.length; i++) {
            menuLinks[i].addEventListener('click', function (e) {
                if(menuLinks[i].classList.contains('js-has-child')){
                    e.preventDefault();
                    menuLinks[i].nextElementSibling.classList.toggle('hidden');
                }
            });
        }
    }
});
